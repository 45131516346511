<script lang="ts" setup>
import CommonAPI from '~/api/common';
import type { ResponseError } from '~/api/utils/types';
import nameLogo from '~/assets/icons/logo/name.svg?url';

const CURRENT_YEAR = new Date().getFullYear();

const { t } = useI18n();
const commonStore = useCommonStore();
const modalStore = useModalStore();
const dataLayer = useDataLayer();

const email = ref('');

const { execute, isLoading } = useRequest(CommonAPI.subscribe, () => ({
  body: { email: email.value },
}));

const onSubmit = () => {
  execute()
    ?.then(() => {
      dataLayer.append({ event: 'email-subscribe' }, ['user']);
      modalStore.showSuccess({
        title: t('message.success'),
        action: t('message.return'),
      });
      email.value = '';
    })
    .catch((e: ResponseError) => {
      modalStore.showError({
        title: getFetchErrorMessage(e, t('message.error')),
        action: t('message.return'),
      });
    });
};
const route = useRoute();
const hideSibscribe = computed(
  () =>
    route.path === '/' ||
    route.path === '/contacts' ||
    route.path === '/catalog' ||
    route.path === '/delivery-and-payment' ||
    route.path === '/faq',
);
</script>

<template>
  <footer class="footer">
    <div class="footer__row">
      <div class="footer__startColumn">
        <NuxtLink to="/" class="footer__logoLink">
          <img :src="nameLogo" alt="Don't Touch My Skin" class="footer__logo" />
        </NuxtLink>
      </div>
      <div class="footer__navigation">
        <div class="footer__smallColumn">
          <NuxtLink to="/about" class="footer__link">
            {{ t('link.about') }}
          </NuxtLink>
          <NuxtLink to="/blog" class="footer__link">
            {{ t('link.blog') }}
          </NuxtLink>
          <NuxtLink to="/social-responsibility" class="footer__link">
            {{ t('link.social_responsibility') }}
          </NuxtLink>
          <NuxtLink to="/contacts" class="footer__link">
            {{ t('link.contacts') }}
          </NuxtLink>
          <NuxtLink to="/delivery-and-payment" class="footer__link">
            {{ t('link.delivery_and_payment') }}
          </NuxtLink>
          <NuxtLink to="/faq" class="footer__link">
            {{ t('link.faq') }}
          </NuxtLink>
          <NuxtLink to="/public-offer" class="footer__link">
            {{ t('link.public_offer') }}
          </NuxtLink>
          <NuxtLink to="/subscribe-product" class="footer__link">
            {{ t('link.subscribe_product') }}
          </NuxtLink>
        </div>
        <div class="footer__smallColumn">
          <NuxtLink to="/catalog" class="footer__link">
            {{ t('link.catalog') }}
          </NuxtLink>
          <NuxtLink
            v-for="item in commonStore.sections"
            :key="item.id"
            :to="{ path: '/catalog', query: { categories: item.code } }"
            class="footer__link"
          >
            {{ item.name }}
          </NuxtLink>
        </div>
      </div>
    </div>

    <div class="footer__row">
      <div class="footer__startColumn">
        <form
          @submit.prevent="onSubmit"
          class="footer__form"
          v-if="!hideSibscribe"
        >
          <span class="footer__hint">{{ t('hint') }}</span>
          <input
            v-model="email"
            autocomplete="email"
            inputmode="email"
            name="email"
            :placeholder="t('placeholder')"
            class="footer__input"
          />
          <BaseButton
            size="s"
            :loading="isLoading"
            type="submit"
            class="footer__button"
          >
            {{ t('subscribe') }}
          </BaseButton>
        </form>

        <div class="footer__social">
          <NuxtLink
            target="_blank"
            to="https://t.me/dtmskin"
            external
            class="footer__socialLink"
            :aria-label="t('social.telegram')"
          >
            <SvgoSimpleTelegram />
          </NuxtLink>
          <NuxtLink
            target="_blank"
            to="https://www.youtube.com/@dtmskin4096"
            external
            class="footer__socialLink"
            :aria-label="t('social.youtube')"
          >
            <SvgoSimpleYoutube />
          </NuxtLink>
          <NuxtLink
            target="_blank"
            to="https://vk.com/dtmskin"
            external
            class="footer__socialLink"
            :aria-label="t('social.vk')"
          >
            <SvgoSimpleVk />
          </NuxtLink>
        </div>
        <div class="footer__social">
          <a :href="`mailto:${t('company.email')}`" class="footer__socialLink">
            {{ t('company.email') }}
          </a>
        </div>
      </div>
    </div>

    <div class="footer__end">
      <span class="footer__copyright">
        {{ t('copyright', { year: CURRENT_YEAR }) }}
      </span>

      <div class="footer__payments">
        <SvgoSimpleMastercard />
        <SvgoSimpleVisa />
        <SvgoSimpleMir />
      </div>
    </div>
  </footer>
</template>

<i18n src="~/locales/company.yaml" />
<i18n>
ru:
  description: Безопасность и комфорт для вашей кожи
  hint: Подписывайтесь на нашу рассылку!
  placeholder: Email
  subscribe: Подписаться
  social:
    telegram: Ссылка на профиль в социальной сети Telegram
    youtube: Ссылка на профиль в социальной сети YouTube
    vk: Ссылка на профиль в социальной сети ВКонтакте
  link:
    about: О бренде
    contacts: Где найти
    public_offer: Публичная оферта
    subscribe_product: Подписка на рефилы
    faq: FAQ
    delivery_and_payment: Доставка и оплата
    social_responsibility: Социальная ответственность
    blog: Блог
    catalog: Все продукты
  cart: Корзина
  profile: Личный кабинет
  copyright: © {year} Don’t touch my skin. Все права защищены
  message:
    success: Вы успешно подписались на рассылку
    error: Произошла ошибка, повторите попытку позже
    return: Вернуться

en:
  description: Safety and comfort for your skin
  hint: Subscribe to our newsletter!
  placeholder: Email
  subscribe: Subscribe
  social:
    telegram: Link to the Telegram social network profile
    youtube: Link to the YouTube social network profile
    vk: Link to the Vkontakte social network profile
  link:
    about: About the brand
    contacts: Contacts
    public_offer: Public offer
    subscribe_product: Subscription for Refills
    faq: FAQ
    delivery_and_payment: Delivery and payment
    social_responsibility: Social responsibility
    blog: Blog
    catalog: All products
  cart: Cart
  profile: Profile
  copyright: © {year} Don’t touch my skin. All rights reserved
  message:
    success: You have successfully subscribed to the newsletter
    error: An error occurred, please try again later
    return: Return
</i18n>

<style lang="scss">
.footer {
  padding: 116px 20px 0;
  background-color: #8ab844;
  color: #fff;

  @include mq('lg') {
    padding: 80px 20px 0;
  }

  @include mq('sm') {
    padding: 30px 16px 0;
  }

  &__row {
    display: flex;
    gap: calc(100% / 12);

    &:nth-of-type(2) {
      margin-top: 0;
    }

    @include mq('lg') {
      &:nth-of-type(2) {
        margin-top: 80px;
      }
    }

    @include mq('sm') {
      flex-direction: column;
      gap: 0;

      &:nth-of-type(2) {
        margin-top: 24px;
      }
    }
  }

  &__startColumn {
    width: calc(100% / 4);

    @include mq('sm') {
      width: 100%;
    }
  }

  &__endColumn {
    flex: 1;
    display: flex;

    @include mq('sm') {
      flex-wrap: wrap;
      column-gap: 16px;
      row-gap: 10px;
    }
  }

  &__logoLink {
    display: block;
    width: fit-content;
  }

  &__logo {
    max-width: 100%;
    width: 245px;
    height: 255px;
    display: block;

    img {
      width: 100%;
    }

    @include mq('sm') {
      width: 142px;
      height: 148px;
    }
  }

  &__description {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.2px;
    margin: 0;
    padding-right: calc(100% / 8);

    @include mq('lg') {
      font-size: 24px;
      line-height: 32px;
    }

    @include mq('sm') {
      font-size: 18px;
      line-height: 24px;
      margin-top: 27px;
    }
  }

  &__form {
    @include mq('sm') {
      display: none;
    }
  }

  &__hint {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    max-width: 181px;
    display: block;
    margin-bottom: 24px;
  }

  &__input {
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    padding: 12px 23px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    background: none;
    outline: none;
    color: #fff;
    display: block;

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  &__button {
    width: 100%;
    margin-top: 24px;
  }

  & &__navigation {
    flex: 1;
    display: flex;
    column-gap: 16px;

    @include mq('lg') {
      row-gap: 10px;
      display: block;
      column-count: 2;
    }
  }

  &__smallColumn {
    display: flex;
    flex-direction: column;
    gap: 16px;
    break-inside: avoid;

    &:not(:last-child) {
      width: 25%;
    }

    @include mq('lg') {
      row-gap: 10px;
      width: 100% !important;
      padding-bottom: 10px;

      &:last-child {
        display: contents;
      }

      &:nth-child(2) {
        order: 2;
      }
    }

    &:empty {
      display: none;
    }
  }

  &__linkSubGroup {
    display: flex;
    flex-direction: column;
    gap: inherit;
    width: inherit;

    &:nth-child(1) {
      order: 2;
    }

    &:empty {
      display: none;
    }
  }

  &__link {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #fff;
    transition: opacity 0.3s ease;
    background: none;
    padding: 0;
    border: none;
    text-align: start;
    cursor: pointer;
    width: fit-content;

    &:hover {
      opacity: 0.7;
    }

    @include mq('sm') {
      font-size: 14px;
      line-height: 20px;
      color: #f2f2f2;
    }
  }

  &__social {
    display: flex;
    gap: 21px;
    margin-top: 31px;

    @include mq('sm') {
      gap: 38px;
      margin-top: 0;
      margin-bottom: 44px;
      justify-content: center;
    }
  }

  &__socialLink {
    color: #f2f2f2;
    padding: 2px;
    width: 35px;
    height: 35px;
    display: block;

    &:hover svg {
      transform: translateY(-6px);
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      transition: transform 0.3s ease;
    }
  }

  &__end {
    padding: 40px 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include mq('sm') {
      flex-direction: column-reverse;
      align-items: center;
      padding: 26px 0 0;
    }
  }

  &__copyright {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    mix-blend-mode: normal;
    opacity: 0.5;
    padding-bottom: 8px;

    @include mq('sm') {
      height: 67px;
      opacity: 0.4;
      display: flex;
      align-items: center;
      padding-bottom: 0;
    }
  }

  &__payments {
    display: flex;
    gap: 17px;
    height: 55px;

    @include mq('sm') {
      height: 45px;
      width: 100%;
      justify-content: center;
      border-bottom: 1px solid rgba(242, 242, 242, 0.1);
    }

    svg {
      height: 100%;

      &:nth-child(1) {
        width: 40px;
      }

      &:nth-child(2) {
        width: 56px;
      }

      &:nth-child(3) {
        width: 56px;
      }
    }
  }
}
</style>
